<template>
  <div class="facilities--field mb-2">
    <v-simple-checkbox
      @click="toggleFacility"
      :id="'facility-with-unit-field-' + id"
      :value="checked"
      :ripple="false"
    />
    <label :for="'facility-with-unit-field-' + id">{{ name }}</label>
    <v-text-field
      class="facility--unit-field ml-3"
      :value="amount"
      @input="facilityAmountChanged"
      type="text"
      name="amounts[]"
      outlined
      hide-details
      :suffix="uomName"
    />
  </div>
</template>

<script>
export default {
  name: 'facility-with-unit-field',
  props: ['name', 'checked', 'id', 'uomName', 'amount'],
  methods: {
    toggleFacility() {
      let self = this;
      self.$store.commit('unitType/form/TOGGLE_CHECK_FACILITY', this.id);
    },
    facilityAmountChanged(value) {
      console.log('facilityAmountChanged', value);
      let self = this;
      self.$store.commit('unitType/form/CHANGE_FACILITY_AMOUNT', {
        facilityId: self.id,
        amount: value,
      });
      if (!this.checked) {
        this.toggleFacility();
      }
    },
  },
};
</script>

<style scoped></style>
